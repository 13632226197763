<template>
  <div class="flex justify-end">
    <Button label="Crear salida" icon="pi pi-plus" class="p-button-success p-mr-2 p-mb-2" @click="$router.push({ name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.salidas.crear' })"/>
  </div>
  <DataTable :value="salidas.rows" class="p-datatable-sm" tableStyle="font-size:0.75rem" size="small">
    <Column field="Id" header="# Entrada">
      <template #body="{data}">
        <span class="text-blue-700 underline hover:text-blue-800 cursor-pointer" @click="$router.push({ name: 'pharmasan.inventario.operaciones-stock.ajuste-inventario.ver', params: { Id: data.Id }})">{{ data.Id }}</span>
      </template>
    </Column>
    <Column header="Fecha Entrada">
      <template #body="{data}">
        <span>{{ dayjs(data.CreatedAt).format('YYYY-MM-DD') }}</span>
      </template>
    </Column>
    <Column field="Motivo" header="Motivo" />
    <Column header="Bodega">
      <template #body="{data}">
        <span>{{ data.WhsCode }} - {{ data.Bodega ? data.Bodega.WhsName : '' }}</span>
      </template>
    </Column>
    <Column header="Comentarios">
      <template #body="{data}">
        <span v-tooltip.bottom="data.Comments">{{ data.Comments ? data.Comments.substring(0,50) : '' }}</span>
      </template>
    </Column>
  </DataTable>
  <Paginator :rows="10" :totalRecords="salidas.count" :rowsPerPageOptions="[10, 20, 30]" @page="onPage"></Paginator>
</template>

<script setup>
import { onMounted, computed } from 'vue'
import { useAjustesInventario } from './store/ajustesInventario.store'
import dayjs from 'dayjs'

const salidas = computed(() => AjustesInventarioStore._Salidas)
const AjustesInventarioStore = useAjustesInventario()

const onPage = (event) => {
  AjustesInventarioStore.getSalidas({
    TipoOperacion: 2,
    offset: event.first,
    limit: event.rows
  })
}

onMounted(async () => {
  await AjustesInventarioStore.getSalidas({
    TipoOperacion: 2,
    offset: 0,
    limit: 10
  })
})
</script>

<style scoped>

</style>
